import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import DashboardRoute from '@app/components/Routes/DashboardRoute';

import Layout from '@app/components/Layout';
import DbaLoader from '@app/components/UI/DbaLoader/DbaLoader';

const params = {
	fallback: <DbaLoader />,
};

const paramsLogo = {
	fallback: <DbaLoader />,
};

/* --------------Admin---------------*/

const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UsersPage" */
		'@app/components/Pages/Admin/Users/Users'
	)), params);
const UserEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserEditorPage" */
		'@app/components/Pages/Admin/Users/UserEditor'
	)), params);
const Pages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */
		'@app/components/Pages/Admin/Page/Pages'
	)), params);
const Companies = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompaniesPage" */
		'@app/components/Pages/Admin/Companies/Companies'
	)), params);
const CompanyEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CompanyEditorPage" */
		'@app/components/Pages/Admin/Companies/CompanyEditor'
	)), params);
const Clients = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClientsPage" */
		'@app/components/Pages/Admin/Clients/Clients'
	)), params);
const ClientEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClientEditorPage" */
		'@app/components/Pages/Admin/Clients/ClientEditor'
	)), params);
const Ethnicities = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EthnicitiesPage" */
		'@app/components/Pages/Admin/Ethnicities/Ethnicities'
	)), params);
const IcdCodes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "IcdCodesPage" */
		'@app/components/Pages/Admin/Codes/IcdCodes'
	)), params);
const CptCodes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CptCodesPage" */
		'@app/components/Pages/Admin/Codes/CptCodes'
	)), params);
const PayerList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PayersPage" */
		'@app/components/Pages/Admin/Payers/Payers'
	)), params);
const CptModifierList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ModifiersPage" */
		'@app/components/Pages/Admin/CptModifiers/CptModifiers'
	)), params);
const PlaceOfServiceList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PlaceOfServicesPage" */
		'@app/components/Pages/Admin/PlaceOfServices/PlaceOfServices'
	)), params);
const Laboratories = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LaboratoriesPage" */
		'@app/components/Pages/Admin/Laboratories/Laboratories'
	)), params);
const LaboratoryEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LaboratoryEditorPage" */
		'@app/components/Pages/Admin/Laboratories/LaboratoryEditor'
	)), params);
const Routines = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RoutinesPage" */
		'@app/components/Pages/Admin/Routines/Routines'
	)), params);
const RoutineEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RoutineEditorPage" */
		'@app/components/Pages/Admin/Routines/RoutineEditor'
	)), params);
const AnalysisSources = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalysisSourcesPage" */
		'@app/components/Pages/Admin/AnalysisSources/AnalysisSources'
	)), params);
const AnalysisLocations = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalysisLocationsPage" */
		'@app/components/Pages/Admin/AnalysisLocations/AnalysisLocations'
	)), params);
const Certificates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CertificatesPage" */
		'@app/components/Pages/Admin/Certificates/Certificates'
	)), params);
const CertificateEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CertificateEditorPage" */
		'@app/components/Pages/Admin/Certificates/CertificateEditor'
	)), params);
const DrugClassList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DrugClassesPage" */
		'@app/components/Pages/Admin/DrugClasses/DrugClasses'
	)), params);
const Specialties = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialtiesPage" */
		'@app/components/Pages/Admin/Specialties/Specialties'
	)), params);
const GlobalProcedureEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalProcedureEditor" */
		'@app/components/Pages/Admin/GlobalProcedures/GlobalProcedureEditor'
	)), params);
const Languages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LanguagesPage" */
		'@app/components/Pages/Admin/Languages/Languages'
	)), params);
const FaxReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaxReport" */
		'@app/components/Pages/Admin/FaxReport/FaxReport'
	)), params);
const MerchantReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MerchantReport" */
		'@app/components/Pages/Admin/MerchantReport/MerchantReport'
	)), params);
const CustomerProfileViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CustomerProfileViewer" */
		'@app/components/Pages/Admin/CustomerProfileViewer/CustomerProfileViewer'
	)), params);
const SpecialtyEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SpecialtyEditor" */
		'@app/components/Pages/Admin/Specialties/SpecialtyEditor'
	)), params);
const SystemMerchantEditor = loadable(() => loadableDelay(
	import(/* webpackChunkName: "SystemMerchantEditor" */
		'@app/components/Pages/Admin/SystemMerchant/SystemMerchantEditor'
	),
), params);
const Transactions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Transactions" */
		'@app/components/Pages/Admin/Transactions/Transactions'
	)), params);
const TransactionViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TransactionViewer" */
		'@app/components/Pages/Admin/Transactions/TransactionViewer'
	)), params);
const Invoices = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InvoicesPage" */
		'@app/components/Pages/Admin/Invoices/Invoices'
	)), params);
const EmailTemplates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplates" */
		'@app/components/Pages/Admin/EmailTemplates/EmailTemplates'
	)), params);
const PricePlans = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PricePlansPage" */
		'@app/components/Pages/Admin/PricePlans/PricePlans'
	)), params);
const GlobalPayers = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalPayerListPage" */
		'@app/components/Pages/Admin/GlobalPayers/GlobalPayers'
	)), params);
const GlobalProcedures = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "GlobalProcedureListPage" */
		'@app/components/Pages/Admin/GlobalProcedures/GlobalProcedures'
	)), params);
const PricePlanEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PricePlanEditorPage" */
		'@app/components/Pages/Admin/PricePlans/PricePlanEditor'
	)), params);
const SmsLogs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SmsLogs" */
		'@commonTuna/react/components/Pages/Admin/SmsLogs/SmsLogs'
	)), params);
const EmailLogs = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailLogs" */
		'@commonTuna/react/components/Pages/Admin/EmailLogs/EmailLogs'
	)), params);
const InquiryReport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InquiryReport" */
		'@app/components/Pages/Admin/InquiryReport/InquiryReport'
	)), params);
const BillingPlans = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlans" */
		'@app/components/Pages/Admin/BillingPlans/BillingPlans'
	)), params);
const BillingPlanEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillingPlanEditor" */
		'@app/components/Pages/Admin/BillingPlans/BillingPlanEditor'
	)), params);
const ColorSets = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ColorSets" */
		'@app/components/Pages/Admin/ColorSets/ColorSets'
	)), params);
const SystemPaymentInquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemPaymentInquiriesPage" */
		'@app/components/Pages/Admin/SystemPaymentInquiries/SystemPaymentInquiries'
	)), params);

/* ------------Admin end-------------*/

// const Home = loadable(() =>
// loadableDelay(import(/* webpackChunkName: "HomePage" */ './components/Pages/Home/Home')), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */ '@app/components/Pages/Login/Login')), params);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */ '@app/components/Pages/Recover/Recover')), params);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ '@app/components/Pages/Confirm/Confirm')), params);
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */ '@app/components/Pages/Profile')), params);
const AccessDenied = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessDenied" */
		'@common/react/components/Pages/AccessDenied/AccessDenied'
	)), paramsLogo);

const dashboardRoutes = [
	{
		title: 'Sms Logs',
		path: '/smsLogs',
		component: (props) => <SmsLogs {...props} withServerPage={false} />,
	},
	{
		title: 'Email Logs',
		path: '/emailLogs',
		component: (props) => <EmailLogs {...props} withServerPage={false} />,
	},
	{
		path: '/billing-plan-editor/:id',
		component: (props) => <BillingPlanEditor {...props} withTitle />,
	},
	{
		title: 'Companies',
		path: '/company-list/:page?',
		component: Companies,
	},
	{
		title: 'Ethnicities',
		path: '/ethnicity-list/:page?',
		component: Ethnicities,
	},
	{
		title: 'Icd-10 Codes',
		path: '/icd-code-list/:page?',
		component: IcdCodes,
	},
	{
		title: 'Cpt Codes',
		path: '/cpt-code-list/:page?',
		component: CptCodes,
	},
	{
		title: 'Payers',
		path: '/payer-list/:page?',
		component: PayerList,
	},
	{
		title: 'Cpt Modifiers',
		path: '/cpt-modifier-list/:page?',
		component: CptModifierList,
	},
	{
		title: 'Place Of Services',
		path: '/place-service-list/:page?',
		component: PlaceOfServiceList,
	},
	{
		title: 'Laboratories',
		path: '/laboratory-list/:page?',
		component: Laboratories,
	},
	{
		title: 'Laboratory Categories',
		path: '/routine-list/:page?',
		component: Routines,
	},
	{
		title: 'Analysis Sources',
		path: '/analysisSource-list/:page?',
		component: AnalysisSources,
	},
	{
		title: 'Analysis Locations',
		path: '/analysisLocation-list/:page?',
		component: AnalysisLocations,
	},
	{
		title: 'Certificates',
		path: '/certificate-list/:page?',
		component: Certificates,
	},
	{
		title: 'Drug classes',
		path: '/drug-class-list/:page?',
		component: DrugClassList,
	},
	{
		title: 'Global payers',
		path: '/global-payer-list/:page?',
		component: GlobalPayers,
	},
	{
		title: 'Global procedures',
		path: '/global-procedure-list/:page?',
		component: GlobalProcedures,
	},
	{
		title: 'Global Procedure Editor',
		path: '/global-procedure-editor/:id?',
		component: GlobalProcedureEditor,
	},
	{
		title: 'Specialties',
		path: '/specialties',
		component: Specialties,
	},
	{
		title: 'Languages Dictionary',
		path: '/languages',
		component: Languages,
	},
	{
		title: 'Specialty Editor',
		path: '/specialty-editor/:id',
		component: SpecialtyEditor,
	},
	{
		title: 'System Merchant',
		path: '/system-merchant',
		component: SystemMerchantEditor,
	},
	{
		title: 'Transactions',
		path: '/transactions/:page?',
		component: Transactions,
	},
	{
		title: 'Email Templates',
		path: '/email-templates',
		component: EmailTemplates,
	},
	{
		title: 'Transactions',
		path: '/transaction-editor/:id',
		component: TransactionViewer,
	},
	{
		title: 'Invoices',
		path: '/invoice-list',
		component: Invoices,
	},
	{
		title: 'Price Plans',
		path: '/price-plans/:page?',
		component: PricePlans,
	},
	{
		title: 'Price Plan Editor',
		path: '/price-plan-editor/:id',
		component: PricePlanEditor,
	},
	{
		title: 'Billing Plans',
		path: '/billing-plans/:page?',
		component: BillingPlans,
	},
	{
		title: 'Color Sets',
		path: '/color-sets/:page?',
		component: ColorSets,
	},
	{
		title: 'System Payment Inquiries',
		path: '/system-payment-inquiry-list/:page?',
		component: SystemPaymentInquiries,
	},
	{
		path: '/user-list/:page?',
		component: Users,
	},
	{
		path: '/user-editor/:id',
		component: UserEditor,
	},
	{
		path: '/page-list/:page?',
		component: Pages,
	},
	{
		path: '/company-editor/:id',
		component: CompanyEditor,
	},
	{
		path: '/client-list/:page?',
		component: Clients,
	},
	{
		path: '/client-editor/:id',
		component: ClientEditor,
	},
	{
		path: '/laboratory-editor/:id',
		component: LaboratoryEditor,
	},
	{
		path: '/routine-editor/:id',
		component: RoutineEditor,
	},
	{
		path: '/certificate-editor/:id',
		component: CertificateEditor,
	},
	{
		path: '/profile',
		component: Profile,
	},
	{
		path: '/fax-list',
		component: FaxReport,
	},
	{
		path: '/merchant-report/:page?',
		component: MerchantReport,
	},
	{
		path: '/inquiry-report/:page?',
		component: InquiryReport,
	},
	{
		title: 'Customer Profile',
		path: '/customer-profile/:id',
		component: CustomerProfileViewer,
	},
];

export const baseRoutes = (<>
	<Route path="/" element={<Login />} />
	<Route path="/login" element={<Login />} />
	<Route path="/recover" element={<Recover />} />
	<Route path="/confirm" element={<Confirm />} />
	{dashboardRoutes.map((item) => <Route
		key={item.path}
		path={item.path}
		element={<DashboardRoute path={item.path} component={item.component} title={item.title} />}
	/>)}
	<Route
		path="/access-denied"
		element={<NotFoundRoute
			title="403 - ACCESS DENIED"
			component={AccessDenied}
			loginRoute={DashboardRoute}
			openRoute={({ component: Component }) => <Component />}
		/>}
	/>
	<Route
		path="*"
		element={<NotFoundRoute
			loginRoute={DashboardRoute}
			openRoute={({ component: Component }) => <Component />}
		/>}
	/>
</>);

export const clientRoutes = (<Route path="/" element={<Layout />}>
	{baseRoutes}
</Route>);

export const routes = (<Layout>
	<Routes>
		{baseRoutes}
	</Routes>
</Layout>);
