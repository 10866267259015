import * as React from 'react';

import Modal from 'antd/lib/modal';
import Message from 'antd/lib/message';

import {
	ModalContextProvider,
} from '@common/react/components/Modal/ModalContextProvider';

const ModalComponent: React.FC<{ visible, close, props, modal? }> = ({
	visible, props, close, modal = Modal.warn,
}) => {
	const ref = React.useRef<any>(null);
	React.useEffect(() => {
		if (visible) {
			ref.current = modal({
				...props,
				content: props.message ?? props.content,
				afterClose: () => {
					props.afterClose?.();
					close();
				},
			});
		} else if (ref.current) {
			ref.current.destroy();
		}
	}, [visible]);
	React.useEffect(() => {
		ref.current && ref.current.update({
			...props,
			content: props.message ?? props.content,
			afterClose: () => {
				props.afterClose?.();
				close();
			},
		});
	}, [props, close]);
	return <></>;
};

export const AntdModalContextProvider: React.FC = ({
	children,
}) => {
	return (
		<ModalContextProvider
			renderModal={(visible, close, props) => {
				return <Modal
					{...props}
					onCancel={() => {
						close(false);
					}}
					afterClose={() => {
						props.afterClose?.();
						close();
					}}
					open={visible}
				/>;
			}}
			renderError={(visible, close, props) => {
				return <ModalComponent
					visible={visible}
					close={close}
					props={props}
					modal={Modal.error}
				/>;
			}}
			renderSuccess={(visible, close, props) => {
				return <ModalComponent
					visible={visible}
					close={close}
					props={props}
					modal={Modal.success}
				/>;
			}}
			renderAlert={(visible, close, props) => {
				return <ModalComponent
					visible={visible}
					close={close}
					props={props}
				/>;
			}}
			renderConfirm={(visible, close, props) => {
				return <ModalComponent
					visible={visible}
					close={close}
					props={{
						content: '',
						okText: 'Yes',
						okType: 'primary',
						cancelText: 'No',
						...props,
					}}
					modal={Modal.confirm}
				/>;
			}}
			renderDeleteConfirm={(visible, close, props) => {
				return <ModalComponent
					visible={visible}
					close={close}
					props={{
						content: '',
						okText: 'Yes',
						okType: 'danger',
						cancelText: 'Cancel',
						className: 'editable-table-delete-modal',
						...props,
						title: props.title ?? 'This item will be permanently deleted and cannot be recovered. Are you sure?',
					}}
					modal={Modal.confirm}
				/>;
			}}
			openErrorMessage={(message, params) => Message.error(params ? { ...params, content: message } : message)}
			openSuccessMessage={(message, params) => Message.success(params ? { ...params, content: message } : message)}
		>
			{children}
		</ModalContextProvider>
	);
};

export default AntdModalContextProvider;
